.address-location .css-yk16xz-control {
  padding: 8px 14px 0px 0px !important;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0px !important;
  box-shadow: none;
}

.address-location .css-yk16xz-control div {
  color: #28285c !important;
}

.address-location .css-1pahdxg-control div {
  color: #28285c !important;
}

.address-location .css-1pahdxg-control:hover {
  border: none !important;
  border-color: transparent !important;
}

.address-location .css-yk16xz-control div:first-child {
  padding-left: 0px !important;
}

.address-location .css-1pahdxg-control div:first-child {
  padding-left: 0px !important;
}

.address-location .css-1pahdxg-control {
  padding: 8px 14px 0px 0px !important;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0px !important;
  box-shadow: none;
}

@media (min-width: 945px) {
  .tanker-container {
    margin-top: -240px;
  }
}

@media (min-width: 1145px) {
  .tanker-container {
    margin-top: -280px;
  }
}

@media (max-width: 990px) {
  .tanker-container {
    display: none !important;
  }
}

@media (max-width: 1390px) {
  .miamidade-promo {
    display: none !important;
  }
}

.residential-use {
  text-align: center;
  color: rgba(255, 255, 255, 0.884);
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  box-sizing: border-box;
  text-transform: uppercase;
  max-width: 100%;
}

.residential-use::before {
  width: calc(100vw - 88vw);
  content: '';
  height: 1px;
  right: 100%;
  margin-right: 20px;
  background-color: rgba(255, 255, 255, 0.671);
  position: absolute;
  top: 50%;
}

.residential-use::after {
  width: calc(100vw - 88vw);
  content: '';
  height: 1px;
  left: 100%;
  margin-left: 20px;
  background-color: rgba(255, 255, 255, 0.671);
  position: absolute;
  top: 50%;
}
